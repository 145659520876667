import { createTheme } from "@mui/material/styles";

const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#333333",
      light: "#c9c9c9"
    },
    secondary: {
      main: "#815b5b"
    },
    error: {
      main: "#f44336"
    },
    warning: {
      main: "#cb7900"
    },
    info: {
      main: "#008dfd"
    },
    success: {
      main: "#4caf50"
    },
    divider: "#000000",
    background: {
      default: "#e0e0e0",
      paper: "#f4f4f4"
    },
    text: {
      primary: "#000000"
    }
  }
};

const theme = createTheme(themeOptions);

export default theme;
