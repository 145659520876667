import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import customTheme from "./layout/theme";
// import CustomAppBar from "./common/appbar";
import Home from "./pages/home";

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      {/* <CustomAppBar /> */}
      <Home />
    </ThemeProvider>
  );
}

// function App() {
//   return <Home />;
// }

export default App;
